import React,{ useState, useCallback, useEffect } from 'react'
import Error from './pages/Error'
import AboutUs from './pages/AboutUs'
import ComingSoon from './pages/ComingSoon'
import Maintenance from './pages/Maintenance'
import Support from './pages/Support'
import OverView from './pages/Overview'
import Guides from './pages/Guides'
import Faqs from './pages/Faqs'
import BlogCoreWallet from './pages/BlogDetail'
import Blog from './pages/Blog'
import Contact from './pages/Contact'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import MintPage from './pages/Mint'
import DarkVersionOne from './pages/DarkVersionOne'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import ScrollToTop from './components/ScrollToTop'
import Navbar from './components/Navbar'
import { ethers } from 'ethers'

export default function Router() {
  const [myPublicAddress, setMyPublicAddress] = useState(null)
  const [web3provider, setWeb3Provider] = useState(null)
  const [networkMessage, setNetworkMessage] = useState('Switch to Avalanche network')
  // const provider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");

  const isMetaMaskInstalled = useCallback(() => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
  }, [])

  const checkWalletConnet = useCallback(async () => {
    if (isMetaMaskInstalled()) {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      setWeb3Provider(provider)
      provider.on("network", (newNetwork, oldNetwork) => {
          console.log(provider.network.chainId )
      });
      
      if (!!accounts[0]) {
        // check if on avalanche mainnet
        const { chainId } = await provider.getNetwork()
        if(chainId != 43113){
          window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: `0x${Number(43114).toString(16)}`,
                rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
                chainName: "Avalanche C-Chain",
                nativeCurrency: {
                    name: "AVAX",
                    symbol: "AVAX",
                    decimals: 18
                },
                blockExplorerUrls: ["https://snowtrace.io/"]
            }]
          //   params: [{
          //     chainId: `0x${Number(43113).toString(16)}`,
          //     rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
          //     chainName: "Avalanche Fuji",
          //     nativeCurrency: {
          //         name: "AVAX",
          //         symbol: "AVAX",
          //         decimals: 18
          //     },
          //     blockExplorerUrls: ["https://testnet.snowtrace.io/"]
          // }]
        })
        }else{
          console.log(accounts[0]+' already connected')
          const walletAddress =
            accounts[0].split('').slice(0, 6).join('') +
            '...' +
            accounts[0]
              .split('')
              .slice(accounts[0].length - 5, accounts[0].length)
              .join('')
          setMyPublicAddress(walletAddress)
        }
      }
    }
  }, [isMetaMaskInstalled])

  useEffect(() => {
    checkWalletConnet()
  }, [checkWalletConnet])

  const handleConnectWallet = useCallback(async () => {
    const modal = document.getElementById('modal-metamask')

    if (!isMetaMaskInstalled()) {
      //meta mask not installed
      modal.classList.add('show')
      modal.style.display = 'block'
      return
    }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' })
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      console.log(accounts[0]);
      const walletAddress =
        accounts[0].split('').slice(0, 6).join('') +
        '...' +
        accounts[0]
          .split('')
          .slice(accounts[0].length - 5, accounts[0].length)
          .join('')
      setMyPublicAddress(walletAddress)
    } catch (error) {
      console.error(error)
    }
  }, [isMetaMaskInstalled])

  return (
    <BrowserRouter>
      <ScrollToTop/>

      {/* Navbar */}
      <Navbar handleConnectWallet={handleConnectWallet} myPublicAddress={myPublicAddress} networkMessage={networkMessage}/>
      <Routes>
   
        {/* auth router  */}
        {/* <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/lock-screen" element={<LockScreen />} /> */}

        {/* special router page  */}
        <Route exact path="/error" element={<Error />} />
        <Route exact path="/comingsoon" element={<ComingSoon />} />
        <Route exact path="/maintenance" element={<Maintenance />} />

        {/* help center routes */}
        <Route exact path="/helpcenter-support-request" element={<Support />} />
        <Route exact path="/helpcenter-overview" element={<OverView />} />
        <Route exact path="/helpcenter-guides" element={<Guides />} />
        <Route exact path="/helpcenter-faqs" element={<Faqs />} />

        {/* template page routes  */}
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/blog/core-wallet" element={<BlogCoreWallet />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/contact" element={<Contact />} />
        {/* <Route exact path="/collections" element={<Collections />} /> */}
        {/* <Route exact path="/artists" element={<Creator />} /> */}

        {/* <Route exact path="/mint" element={<MintPage />} /> */}
        <Route exact path="/mint/:mintId" element={<MintPage handleConnectWallet={handleConnectWallet} myPublicAddress={myPublicAddress} provider={web3provider}/>} />
        
        <Route exact path="/home" element={<DarkVersionOne />} />
        <Route exact path="/" element={<DarkVersionOne />} />
      
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  )
}
