import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowRight, FiFacebook, FiTwitter, FiInstagram, FiLink } from 'react-icons/fi'
import Footer from '../../components/Footer'
// image import
import {
  bg1, bg2, bg3, cta,
  teamLprofessor, teamAcid, teamLazo, teamTsirides, teamYagos , artistKellogs,
  bg01,
  united, community,
} from "../../components/imageImport";

const AboutUs = () => {
  const navigate = useNavigate()
  const teamMembers = [
    {
      image: teamLprofessor,
      name: 'L Professor',
      position: 'Co-Founder / Music NFTs',
      social: {'facebook': 'https://www.facebook.com/ProfessorDnB','instagram': 'https://www.instagram.com/lprofessor', 'twitter': 'https://twitter.com/L_ProfessorGR'}
    },
    {
      image: teamAcid,
      name: '0xAcid',
      position: 'Co-Founder / Development',
    },
    {
      image: artistKellogs,
      name: 'KellogsPool',
      position: 'Art Direction',
    },
    {
      image: teamLazo,
      name: 'Lazo',
      position: 'Art & Design',
      social: {'links': 'https://lazo.ooo/'}
    },
    {
      image: teamTsirides,
      name: 'Matthew Tsirides',
      position: 'Community',
    },
    {
      image: teamYagos,
      name: 'yagos.eth',
      position: 'Community',
    },
    // {
    //   image: client06,
    //   name: 'Roger Jackson',
    //   position: 'Designer',
    // },
    // {
    //   image: client07,
    //   name: 'Johnny English',
    //   position: 'Designer',
    // },
  ]

  const blogRecord = [
    {
      image: bg1,
      title: 'Mindfulness Activities for Kids & Toddlers with NFT',
      createdBy: '@callyjoe',
      type: 'Arts',
    },
    {
      image: bg2,
      title: 'Save Thousands Of Lives Through This NFT',
      createdBy: '@kristyhoney',
      type: 'Illustration',
    },
    {
      image: bg3,
      title: 'A place where technology meets craftsmanship',
      createdBy: '@pandaone',
      type: 'Music',
    },
  ]

  return (
    <>
      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Meet NFT Art Greece
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  A hub aiming to bring artists & collectors together, paving the way for the new web3 era
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="/index"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/index')
                    }}
                  >
                    NFT Art Greece
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About us
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start Section */}
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="about-image position-relative">
                <img src={cta} className="img-fluid rounded shadow" alt="" />
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h6 className="text-primary fw-normal">
                  Creative Vision & Mission
                </h6>
                <h4 className="title mb-2">
                  We onboard creators & brands
                </h4>
                <p className="text-muted">
                  At NFT Art Greece we believe the future is more than just digital, and place a conviction bet that web3 technology & NFTs will dominate in the near future.
                </p>
                <p className="text-muted mb-4">
                  To prepare for this upcoming future, we strive to onboard artists & brands to explore the powerful tool web3 can be to distribute their art/message.
                </p>

                <h4 className="title mb-2">
                  We educate & bring collectors in the space
                </h4>
                <p className="text-muted">
                  Web3 constitues a paradigm shift to they way we are used to buy,consume & own art. Our goal is to pass on to the community the spirit of this evolving culture and economy that comes along with it.
                </p>
                <h4 className="title mb-2">
                  We help connect artists with their fans
                </h4>
                <p className="text-muted">
                  One of the main reasons web3 & NFT culture is invaluable for artists & brands, is its ability to directly connect with fans. We aim to assist creators to come in touch with their audience, and build long lasting relationships that give value both to both sides.
                </p>
                
              </div>
              
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-md-4 col-6">
                  <div className="counter-box position-relative text-center">
                    <h4 className="mb-0 display-5 fw-bold title-dark mt-2">
                      <span className="counter-value" data-target="7">
                        7
                      </span>
                    </h4>
                    <span className="counter-head fw-semibold text-muted title-dark">
                      Collabs
                    </span>
                  </div>
                  {/*end counter box*/}
                </div>
                {/*end col*/}

                <div className="col-md-4 col-6">
                  <div className="counter-box position-relative text-center">
                    <h4 className="mb-0 display-5 fw-bold title-dark mt-2">
                      <span className="counter-value" data-target="5">
                        5
                      </span>
                    </h4>
                    <span className="counter-head fw-semibold text-muted title-dark">
                      NFT collections launched
                    </span>
                  </div>
                  {/*end counter box*/}
                </div>
                {/*end col*/}

                <div className="col-md-4 col-6">
                  <div className="counter-box position-relative text-center">
                    <h4 className="mb-0 display-5 fw-bold title-dark mt-2">
                      <span className="counter-value" data-target="4000">
                        4000+
                      </span>
                    </h4>
                    <span className="counter-head fw-semibold text-muted title-dark">
                      Community members
                    </span>
                  </div>
                  {/*end counter box*/}
                </div>
                {/*end col*/}
              </div>
              {/*end row*/}
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4">Our Team</h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                  We are a group of artists, creators and developers, aligned towards building a web3-powered future
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="row">
            {teamMembers?.map(teamMember => (
              <div
                className="col-lg-3 col-md-4 col-12 mt-4 pt-2"
                key={teamMember?.name}
              >
                <div className="card team team-primary text-center">
                  <div className="card-img team-image d-inline-block mx-auto rounded-pill shadow avatar avatar-ex-large overflow-hidden">
                    <img src={teamMember?.image} className="img-fluid" alt="" />
                    <div className="card-overlay avatar avatar-ex-large rounded-pill"></div>
                    {teamMember.hasOwnProperty('social') && 
                      <ul className="list-unstyled team-social mb-0">
                        {teamMember.social.hasOwnProperty('facebook') &&
                        <li className="list-inline-item">
                          <a href={teamMember.social.facebook} target="_blank" className="btn btn-sm btn-pills btn-icon">
                            <FiFacebook className="fea icon-sm fea-social" />
                          </a>
                        </li>
                        }
                        {teamMember.social.hasOwnProperty('instagram') &&
                        <li className="list-inline-item">
                          <a href={teamMember.social.instagram} target="_blank" className="btn btn-sm btn-pills btn-icon">
                            <FiInstagram className="fea icon-sm fea-social" />
                          </a>
                        </li>
                        }
                        {teamMember.social.hasOwnProperty('twitter') &&
                        <li className="list-inline-item">
                          <a href={teamMember.social.twitter} target="_blank" className="btn btn-sm btn-pills btn-icon">
                            <FiTwitter className="fea icon-sm fea-social" />
                          </a>
                        </li>
                        }
                        {teamMember.social.hasOwnProperty('links') &&
                        <li className="list-inline-item">
                          <a href={teamMember.social.links} target="_blank" className="btn btn-sm btn-pills btn-icon">
                            <FiLink className="fea icon-sm fea-social" />
                          </a>
                        </li>
                        }
                      </ul>
                    }
                    {/*end icon*/}
                  </div>

                  <div className="content mt-3">
                    <a href="" className="text-dark h6 mb-0 title d-block">
                      {teamMember?.name}
                    </a>
                    <small className="text-muted mb-0 fw-normal">
                      {teamMember?.position}
                    </small>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <p className="text-muted para-desc mb-0 mx-auto">
                  Are you an artist interested in collaborating?<br></br>
                  Or a brand looking to make their first steps in web3?<br></br>
                  <a
                    href="/contact"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/contact')
                    }}>
                    Drop us a line!
                  </a>
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="row">
            <div className="col-md-6 mt-4 pt-2">
              <div className="card p-4 rounded-md shadow bg-white">
                <h4 className="mb-4">Join our community</h4>
                <p className="text-muted mb-0">
                  We use Discord & social media to keep the members of the community connected, as well as for news and promos. Stay connected! 
                </p>

                <div className="mt-3">
                <ul className="list-unstyled social-icon foot-social-icon mb-1 mt-2 text-lg-end">
                  <li
                    className="list-inline-item lh-1"
                    style={{ paddingRight: 3 }}
                  >
                    <a href="https://www.facebook.com/groups/705836573431342" target="_blank" className="rounded">
                      <i className="uil uil-facebook-f"></i>
                    </a>
                  </li>
                  <li
                    className="list-inline-item lh-1"
                    style={{ paddingRight: 3 }}
                  >
                    <a href="https://www.instagram.com/nft_art_greece/" target="_blank" className="rounded">
                      <i className="uil uil-instagram"></i>
                    </a>
                  </li>
                  <li
                    className="list-inline-item lh-1"
                    style={{ paddingRight: 3 }}
                  >
                    <a href="https://twitter.com/NFT_Art_Greece" target="_blank" className="rounded">
                      <i className="uil uil-twitter"></i>
                    </a>
                  </li>
                  <li
                    className="list-inline-item lh-1"
                    style={{ paddingRight: 3 }}
                  >
                    <a href="https://discord.gg/2uHdXYjvxU" target="_blank" className="rounded">
                      <i className="uil uil-discord"></i>
                    </a>
                  </li>
                </ul>
                </div>
                <div className="position-absolute bottom-0 end-0">
                  <img
                    src={community}
                    className="avatar avatar-medium opacity-05"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-md-6 mt-4 pt-2">
              <div className="card p-4 rounded-md shadow bg-white">
                <h4 className="mb-4">Learn more about NFTs</h4>
                <p className="text-muted mb-0">
                  Are NFTs, wallets, blockchain, and all that crypto jargon new to you? Follow our onboarding guide!
                </p>

                <div className="mt-3">
                  <a
                    href="/blog"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/blog')
                    }}
                    className="btn btn-link primary text-dark"
                  >
                    Read More <i className="uil uil-arrow-right"></i>
                  </a>
                </div>
                <div className="py-4"></div>
                <div className="position-absolute bottom-0 end-0">
                  <img
                    src={united}
                    className="avatar avatar-medium opacity-05"
                    alt=""
                  />
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Section */}

      {/* Footer */}
      <Footer />
    </>
  )
}

export default AboutUs
