import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FiArrowRight } from 'react-icons/fi'
import Countdown from 'react-countdown'
import { tns } from 'tiny-slider/src/tiny-slider';
import Footer from '../../components/Footer'

import {
  bg01, united, community,
  work1, work2, work3, work4, work5, work6, work7, work8,
  iconLogo,
  teamLazo,
  artistBagdhad,artistDanny,artistGioMelody,artistIlleoo,artistKellogs,
  genesisBanner,
  brandCannax,brandKeepItPure,brandSneaker10,
  coreWallet
} from '../../components/imageImport'
import UpcomingMint from '../../components/Hero/UpcomingMint'

const DarkVersionOne = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (document.getElementsByClassName('tiny-five-item-nav-arrow').length > 0) {
      var slider6 = tns({
        container: '.tiny-five-item-nav-arrow',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: 'bottom',
        controlsText: [
          '<i class="mdi mdi-chevron-left "></i>',
          '<i class="mdi mdi-chevron-right"></i>',
        ],
        nav: false,
        speed: 400,
        gutter: 10,
        responsive: {
          992: {
            items: 5,
          },

          767: {
            items: 3,
          },

          320: {
            items: 1,
          },
        },
      })
    }
  }, [])

  const toggleSwitcher = () => {
    var i = document.getElementById('style-switcher')
    if (i) {
      if (i.style.left === '-189px') {
        i.style.left = '0px'
      } else {
        i.style.left = '-189px'
      }
    }
  }

  const creator = [
    {
      background: work1,
      Image: artistIlleoo,
      name: 'iLLEOo',
      title: 'Artist',
      link: 'https://www.instagram.com/illeoo_kip/'
    },
    {
      background: work1,
      Image: teamLazo,
      name: 'Lazo',
      title: 'Artist',
      link: 'https://lazo.ooo/'
    },
    {
      background: work2,
      Image: brandSneaker10,
      name: 'sneaker10',
      title: 'Sneakers Shop',
      link: 'https://www.sneaker10.gr/'
    },
    {
      background: work3,
      Image: brandCannax,
      name: 'Canna-x',
      title: 'CBD/Hemp Products',
      link: 'https://hempoilshop.gr/'
    },
    {
      background: work4,
      Image: brandKeepItPure,
      name: 'Keep It Pure',
      title: 'Clothing Brand',
      link: 'https://www.keepitpure.co/'
    },
    {
      background: work5,
      Image: artistKellogs,
      name: 'Kelloggs Pool',
      title: 'Artist',
      link: 'https://www.instagram.com/kelloggs_pool/'
    },
    {
      background: work6,
      Image: artistGioMelody,
      name: 'Gio Melody',
      title: 'Artist',
      link: 'https://www.instagram.com/darkrnbgoat/'
    },
    {
      background: work7,
      Image: artistBagdhad,
      name: 'Baghdad',
      title: 'Artist',
      link: 'https://www.instagram.com/baghdad.prod'
    },
    {
      background: work8,
      Image: iconLogo,
      name: 'Danny',
      title: 'Artist',
      link: 'https://www.instagram.com/danny_hades'
    },
  ]

  
  const blogList = [
    {
      image: coreWallet,
      title: 'Guide: Avalanche Core Wallet',
      type: 'Guide',
      link: '/blog/core-wallet',
      date: '20 July 2022'
    },
  ]

  
  const getClosest = (elem, selector) => {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) { }
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;

  };
  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');
        var immediateParent = getClosest(matchingMenuItem, 'li');
        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        var parent = getClosest(matchingMenuItem, '.parent-menu-item');
        if (parent) {
          parent.classList.add('active');
          var parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }
          var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }
  }
  var TxtType = function (el, toRotate, period) {
    this.toRotate = toRotate
    this.el = el
    this.loopNum = 0
    this.period = parseInt(period, 10) || 2000
    this.txt = ''
    this.tick()
    this.isDeleting = false
  }

  TxtType.prototype.tick = function () {
    var i = this.loopNum % this.toRotate.length
    var fullTxt = this.toRotate[i]
    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1)
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1)
    }
    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>'
    var that = this
    var delta = 200 - Math.random() * 100
    if (this.isDeleting) {
      delta /= 2
    }
    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period
      this.isDeleting = true
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false
      this.loopNum++
      delta = 500
    }
    setTimeout(function () {
      that.tick()
    }, delta)
  }
  const typewrite = () => {
    if (toRotate !== 'undefined')
      var elements = document.getElementsByClassName('typewrite')
    for (var i = 0; i < elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-type')
      var period = elements[i].getAttribute('data-period')
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period)
      }
    }
    // INJECT CSS
    var css = document.createElement('style')
    css.type = 'text/css'
    css.innerHTML =
      '.typewrite > .wrap { border-right: 0.08em solid transparent}'
    document.body.appendChild(css)
  }

  useEffect(() => {
    typewrite()
  }, [])

  return (
    <>

      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) background-position: center center` }}
      // style="background-image: url('images/bg/bg01.png'); background-position: center center;"
      >
        <div className="bg-overlay bg-gradient-primary opacity-8"></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6">
              <div className="title-heading">
                <h6 className="text-light title-dark fw-normal">
                Explore web3 & collect NFTs
                </h6>
                <h4 className="heading text-white title-dark fw-bold mb-3">
                  Join A New Era of <br />{' '}
                  <span
                    className="typewrite"
                    data-period="2500"
                    data-type='[ "Music", "Digital Arts", "Gaming", "Event Tickets", "Ownership"]'
                  ></span>
                </h4>
                <p className="text-white-50 para-desc mb-0 mb-0">
                  We are a creative hub dedicated to onboard 
                  creators, collectors & brands to web3, and build a strong
                  community that brings value to its members!
                </p>

                <div className="mt-4 pt-2">
                  <a
                    href="/aboutus"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/aboutus')
                    }}
                    className="btn btn-pills btn-outline-light-white"
                  >
                    About Us
                  </a>
                </div>
              </div>
            </div>
            {/*end slide*/}

            <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <UpcomingMint/>
            </div>
            {/*end slide*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Home */}

      {/* Start */}
      <section className="section">
        <div className="container">
          <div className="row align-items-end mb-4 pb-2">
            <div className="col-md-8">
              <div className="section-title">
                <h4 className="title mb-2">Collabs</h4>
                <p className="text-muted mb-0">
                  Upcoming / past collabs with artists & brands
                </p>
              </div>
            </div>
            {/*end slide*/}

            <div className="col-md-4">
              <div className="text-end d-md-block d-none">
                <a
                  href="/artists"
                  onClick={e => {
                    e.preventDefault()
                    navigate('/artists')
                  }}
                  className="btn btn-link primary text-dark"
                >
                  See More <i className="uil uil-arrow-right"></i>
                </a>
              </div>
            </div>
            {/*end slide*/}
          </div>
          {/*end row*/}

          <div className="row">
            <div className="col-12 mt-3">
              <div className="tiny-five-item-nav-arrow">
                {creator?.map((data, index) => {
                  return (
                    <div className="tiny-slide" key={data?.name}>
                      <div className="card creators creators-two creator-primary rounded-md shadow overflow-hidden mx-2 my-3">
                        <div
                          className="py-5"
                          style={{ background: `url(${data?.background})` }}
                        ></div>
                        <div className="position-relative mt-n5">
                          <img
                            src={data?.Image}
                            className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block"
                            alt=""
                          />

                          <div className="content text-center pt-2 p-4">
                            <a
                              href={data?.link}
                              target="_blank"
                              className="text-dark h6 name d-block mb-0"
                            >
                              {data?.name}
                            </a>
                            <small className="text-muted">
                              {data?.title}
                            </small>
                            {/* <div className="mt-3">
                              <a
                                href=""
                                onClick={e => e.preventDefault()}
                                className="btn btn-pills btn-soft-primary"
                              >
                                Follow
                              </a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
                {/*end slide*/}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          {/* <div className="row">
            <div className="col">
              <div className="text-center d-md-none d-block">
                <a
                  href="/artists"
                  onClick={e => {
                    e.preventDefault()
                    navigate('/artists')
                  }}
                  className="btn btn-link primary text-dark"
                >
                  See More <i className="uil uil-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}
          {/*end row*/}
        </div>
        {/*end container*/}

        <div>
          <div className="container-fluid mt-100 mt-60">
            <div className="row px-0">
              <div className="bg-half-100 bg-gradient-primary">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col">
                      <div className="section-title text-center mb-4 pb-2">
                        <h4 className="title text-white title-dark mb-4">
                          Enter the fastest growing hub for NFT creators <br /> and collectors in Greece
                        </h4>
                        <p className="text-white-50 para-desc mb-0 mx-auto">
                          Our goal is to empower artists & brands in the NFT space,
                           as well as educate collectors and give tangible value to the members of our community!
                        </p>
                      </div>
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}

                  <div className="row">
                    <div className="col-md-6 mt-4 pt-2">
                      <div className="card p-4 rounded-md shadow bg-white">
                        <h4 className="mb-4">Join our community</h4>
                        <p className="text-muted mb-0">
                          We use Discord & social media to keep the members of the community connected, as well as for news and promos. Stay connected! 
                        </p>

                        <div className="mt-3">
                        <ul className="list-unstyled social-icon foot-social-icon mb-1 mt-2 text-lg-end">
                          <li
                            className="list-inline-item lh-1"
                            style={{ paddingRight: 3 }}
                          >
                            <a href="https://www.facebook.com/groups/705836573431342" target="_blank" className="rounded">
                              <i className="uil uil-facebook-f"></i>
                            </a>
                          </li>
                          <li
                            className="list-inline-item lh-1"
                            style={{ paddingRight: 3 }}
                          >
                            <a href="https://www.instagram.com/nft_art_greece/" target="_blank" className="rounded">
                              <i className="uil uil-instagram"></i>
                            </a>
                          </li>
                          <li
                            className="list-inline-item lh-1"
                            style={{ paddingRight: 3 }}
                          >
                            <a href="https://twitter.com/NFT_Art_Greece" target="_blank" className="rounded">
                              <i className="uil uil-twitter"></i>
                            </a>
                          </li>
                          <li
                            className="list-inline-item lh-1"
                            style={{ paddingRight: 3 }}
                          >
                            <a href="https://discord.gg/2uHdXYjvxU" target="_blank" className="rounded">
                              <i className="uil uil-discord"></i>
                            </a>
                          </li>
                        </ul>
                        </div>
                        <div className="position-absolute bottom-0 end-0">
                          <img
                            src={community}
                            className="avatar avatar-medium opacity-05"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/*end col*/}

                    <div className="col-md-6 mt-4 pt-2">
                      <div className="card p-4 rounded-md shadow bg-white">
                        <h4 className="mb-4">Learn more about NFTs</h4>
                        <p className="text-muted mb-0">
                          Are NFTs, wallets, blockchain, and all that crypto jargon new to you? Follow our onboarding guide!
                        </p>

                        <div className="mt-3">
                          <a
                            href="/blog"
                            onClick={e => {
                              e.preventDefault()
                              navigate('/blog')
                            }}
                            className="btn btn-link primary text-dark"
                          >
                            Read More <i className="uil uil-arrow-right"></i>
                          </a>
                        </div>
                        <div className="py-4"></div>
                        <div className="position-absolute bottom-0 end-0">
                          <img
                            src={united}
                            className="avatar avatar-medium opacity-05"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
                </div>
                {/*end container*/}
              </div>
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}

          <div className="container-fluid">
            <div className="row px-0">
              <div class="col px-0">
                <a 
                  href="/mint/genesis" 
                  onClick={e => {
                    e.preventDefault()
                    navigate('/mint/genesis')
                  }}>
                  <img
                    src={genesisBanner}
                    style={{maxWidth: '100%'}}
                  ></img>
                </a>
              </div>
            </div>
          </div>

          <div className="container mt-100 mt-60">
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center mb-5 pb-3">
                  <h4 className="title mb-4">Blog</h4>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    Guides for beginners - News and opinions on the art & NFT markets - Artist showcases
                  </p>
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}

            <div className="row g-4">
              {blogList?.map((data, index) => {
                return (
                  <div className="col-lg-4 col-md-6" key={'blog-'+index}>
                    <div className="card blog blog-primary shadow rounded-md overflow-hidden">
                      <div className="position-relative">
                        <img
                          src={data?.image}
                          className="img-fluid rounded-md"
                          alt=""
                        />
                      </div>
                      <div className="card-body position-relative p-4">
                        <a
                          href=""
                          className="badge tag gradient rounded-md fw-bold"
                        >
                          {data?.type}
                        </a>

                        <ul className="list-unstyled mt-2">
                          <li className="list-inline-item text-muted small me-3">
                            <i className="uil uil-calendar-alt text-dark h6 me-1"></i>
                            {data?.date}
                          </li>
                          <li className="list-inline-item text-muted small">
                            <i className="uil uil-clock text-dark h6 me-1"></i>5
                            min read
                          </li>
                        </ul>
                        <a
                          href={data?.link}
                          onClick={e => {
                            e.preventDefault()
                            navigate(data?.link)
                          }}
                          className="text-dark title h5 mt-3"
                        >
                          {data?.title}
                        </a>

                        <div className="mt-3 d-flex justify-content-between align-items-center">
                          <a
                            href={data?.link}
                            onClick={e => {
                              e.preventDefault()
                              navigate(data?.link)
                            }}
                            className="btn btn-link text-muted"
                          >
                            Read more <FiArrowRight className="fea icon-sm" />
                          </a>
                          <span className="text-muted fs-6">
                            {' '}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end container*/}
        </div>
        {/*end section*/}
        {/* End */}
      </section>
      {/* footer */}
      <Footer />
    </>
  )
}

export default DarkVersionOne
