import React from 'react'
import { useNavigate } from 'react-router-dom'
import Countdown from 'react-countdown'
import {
    artistIlleoo,teamLazo,brandCannax,brandSneaker10,brandKeepItPure,genesisPreview,avalanche
  } from '../../components/imageImport'

const UpcomingMint = () => {
  const navigate = useNavigate()
  return (
    <>
<div className="card bg-white nft-items nft-primary img-skewed rounded-md shadow overflow-hidden mb-1 p-3">
                <div className="d-flex justify-content-between">
                  <div className="img-group">
                    <a
                      href="#"
                      className="user-avatar"
                    >
                      <img
                        src={brandSneaker10}
                        alt="user"
                        className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                      />
                    </a>
                    <a
                      href="#"
                      className="user-avatar ms-n3"
                    >
                      <img
                        src={brandCannax}
                        alt="user"
                        className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                      />
                    </a>
                    <a
                      href="#"
                      className="user-avatar ms-n3"
                    >
                      <img
                        src={brandKeepItPure}
                        alt="user"
                        className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                      />
                    </a>
                    <a
                      href="#"
                      className="user-avatar ms-n3"
                    >
                      <img
                        src={teamLazo}
                        alt="user"
                        className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                      />
                    </a>
                    <a
                      href="#"
                      className="user-avatar ms-n3"
                    >
                      <img
                        src={artistIlleoo}
                        alt="user"
                        className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                      />
                    </a>
                    <span style={{fontSize: 20, marginLeft: 4, verticalAlign: 'middle', fontWeight: 'bold'}}>iLLEOo - Genesis</span>
                  </div>

                  <span className="like-icon shadow-sm">
                    <a
                      href=""
                      onClick={e => e.preventDefault()}
                      className="text-muted icon"
                    >
                      <img
                        src={avalanche}
                        alt="Avalanche"
                        className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                      />
                    </a>
                  </span>
                </div>

                <div className="nft-image rounded-md mt-3 position-relative overflow-hidden shadow">
                  <a
                    href="/mint/genesis"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/mint/genesis')
                    }}
                  >
                    <img src={genesisPreview} className="img-fluid" alt="" />
                  </a>

                  <div className="position-absolute top-0 end-0 m-2">
                  <a
                      href=""
                      onClick={e => e.preventDefault()}
                      className="badge badge-link bg-danger"
                    >
                      Avalanche
                    </a>
                  </div>

                  <div className="position-absolute bottom-0 start-0 m-2 bg-gradient-primary text-white title-dark rounded-pill px-3 h5">
                    <i className="uil uil-clock"></i>{' '}
                    <Countdown
                      date={'Jul 22, 2022 19:30:0'}
                      renderer={({ days, hours, minutes, seconds }) => (
                        <span>
                          {days}:{hours}:{minutes}:{seconds}
                        </span>
                      )}
                    />
                  </div>
                </div>

                <div className="card-body content position-relative p-0 mt-3">
                  <a
                    href="/mint/genesis"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/mint/genesis')
                    }}
                    className="title text-dark h5"
                  >
                    Mint by NFT Art Greece
                  </a>

                  <div className="d-flex justify-content-between mt-2">
                  <small className="rate fw-bold">0.5 AVAX</small>
                    <small className="text-dark fw-bold">1200 pieces</small>
                  </div>
                </div>
              </div>
    </>
  )
}

export default UpcomingMint
