import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FiArrowRight } from 'react-icons/fi'
import Footer from '../../components/Footer'
import { bg02, coreWallet } from '../../components/imageImport'

const Blog = () => {
  const navigate = useNavigate()
  const blogList = [
    {
      image: coreWallet,
      title: 'Guide: Avalanche Core Wallet',
      type: 'Guide',
      link: '/blog/core-wallet',
      date: '20 July 2022'
    },
  ]
  return (
    <>
      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg02}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  NFT Art Greece Blog
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Guides for beginners - News and opinions on the art & NFT markets - Artist showcases
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="/"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/')
                    }}
                  >
                    NFT Art Greece
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Blog
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start Blog */}
      <section className="section">
        <div className="container">
          <div className="row g-4">
            {blogList?.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={index}>
                <div className="card blog blog-primary shadow rounded-md overflow-hidden">
                      <div className="position-relative">
                        <img
                          src={data?.image}
                          className="img-fluid rounded-md"
                          alt=""
                        />
                      </div>
                      <div className="card-body position-relative p-4">
                        <a
                          href=""
                          className="badge tag gradient rounded-md fw-bold"
                        >
                          {data?.type}
                        </a>

                        <ul className="list-unstyled mt-2">
                          <li className="list-inline-item text-muted small me-3">
                            <i className="uil uil-calendar-alt text-dark h6 me-1"></i>
                            {data?.date}
                          </li>
                          <li className="list-inline-item text-muted small">
                            <i className="uil uil-clock text-dark h6 me-1"></i>5
                            min read
                          </li>
                        </ul>
                        <a
                          href={data?.link}
                          onClick={e => {
                            e.preventDefault()
                            navigate(data?.link)
                          }}
                          className="text-dark title h5 mt-3"
                        >
                          {data?.title}
                        </a>

                        <div className="mt-3 d-flex justify-content-between align-items-center">
                          <a
                            href={data?.link}
                            onClick={e => {
                              e.preventDefault()
                              navigate(data?.link)
                            }}
                            className="btn btn-link text-muted"
                          >
                            Read more <FiArrowRight className="fea icon-sm" />
                          </a>
                          <span className="text-muted fs-6">
                            {' '}
                          </span>
                        </div>
                      </div>
                    </div>
              </div>
            ))}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Blog */}
      {/* footer */}
      <Footer />
    </>
  )
}

export default Blog
