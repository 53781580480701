import React from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import { bg01, iconLogo } from '../../components/imageImport'

const Contact = () => {
  const navigate = useNavigate()
  return (
    <>

      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Contact Us
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Get in Touch !
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
          <div className="row mt-5 justify-content-center text-center">
            <div className="col-12">
              <img src={iconLogo} style={{width: 120}} />
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul
                className="breadcrumb breadcrumb-muted mb-0 p-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <li className="breadcrumb-item">
                  <a
                    href="/index"
                    onClick={e => {
                      e.preventDefault()
                      navigate('/index')
                    }}
                  >
                    NFT Art Greece
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start Section */}
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-discord d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Discord</h5>
                  <p className="text-muted">
                    Get in touch with the team & join our discord community
                  </p>
                  <a href="https://discord.gg/2uHdXYjvxU" className="text-primary" target="_blank">
                  discord.gg/2uHdXYjvxU
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-envelope d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Email</h5>
                  <p className="text-muted">
                    Contact us via email
                  </p>
                  <a href="mailto:nftartgreece@gmail.com" className="text-primary">
                    nftartgreece@gmail.com
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
              <div className="card border-0 text-center features feature-primary feature-clean">
                <div className="icons text-center mx-auto">
                  <i className="uil uil-twitter d-block rounded-md h3 mb-0"></i>
                </div>
                <div className="content mt-4 px-4">
                  <h5 className="fw-bold">Twitter</h5>
                  <p className="text-muted">
                    Follow us on Twitter for news and updates
                  </p>
                  <a
                    href="https://twitter.com/NFT_Art_Greece"
                    target="_blank"
                    className="text-primary"
                  >
                    @NFT_Art_Greece
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        
      </section>
      {/*end section*/}

      {/* footer */}
      <Footer />

    </>
  )
}

export default Contact
