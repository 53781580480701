import React, { useEffect, useState } from 'react'
import { useNavigate , useParams} from 'react-router-dom'
import Footer from '../../components/Footer'
import Countdown from 'react-countdown'
import { BigNumber, ethers } from 'ethers'
import { client01, client02, client03, client08, client09, client10, item1, item2, gif1, gif2, itemDetail1 , 
  teamLazo,artistIlleoo,brandSneaker10,brandCannax,brandKeepItPure,
  genesisPreview,genesisPreview2,genesisPreview3,genesisPreview4
} from '../../components/imageImport'
import contractAbi from '../../abi.json'

const MintPage = ({handleConnectWallet, myPublicAddress, provider}) => {
  const navigate = useNavigate()
  const [mintPriceUSD, setMintPriceUSD] = useState('-')
  const [mintLive, setMintLive] = useState(false)
  const [mintEnded, setMintEnded] = useState(false)
  const [mintTotal, setMintTotal] = useState('0.5')
  const [mintAmount, setMintAmount] = useState(1)
  const [mintMessage,setMintMessage] = useState("Waiting for transaction..")


  const mintData = {
    mintPrice: 0.5,
    mintCurrency: 'AVAX',
    collectionName: 'iLLEOo - Genesis',
    collectionDescription: '<p>Genesis is the first NFT art collection of artist iLLEOo, in collaboration with Greek artists and brands debuting in web3, with a clear manifest: connect the artist with his fans, while giviving back to the people that keep supporting his work.</p> <p>Our own @Lazo hand-drew the "DNA" that makes up the 1200 distinct NFTs of the collection, ready to use as a profile pic and flex you are a unique member of iLLEOo gang!</p> <p>But there\'s more...</p> <ul><li>NFTs with a Sneaker10 or Canna-x item are eligible for discounts on the brand\'s eshop</li><li>Genesis NFTs count as raffle tickets for a pair of Air Jordans AJ12, courtesy of Sneaker10</li></ul>',
    totalSupply: 1200,
    reserved: 60,
    mintDate: '2022-07-22 19:30:00',
    contractAddress: "0xc74fe1e6e160f13279965c812B57FA91b30e9EE5"
  }

  const artists = [{
    name: "iLLEOo",
    image: artistIlleoo,
    link: ""
  },{
    name: "Lazo",
    image: teamLazo,
    link: ""
  }]

  const partners = [{
    name: "Sneaker10",
    image: brandSneaker10,
    link: ""
  },{
    name: "Canna-x",
    image: brandCannax,
    link: ""
  },{
    name: "KeepItPure",
    image: brandKeepItPure,
    link: ""
  }]
  async function getChain(_provider) {
      let chainId = await _provider.getNetwork()
      return chainId.chainId
  }
  const handleAmountChanged = (e) => {
    let amount = parseInt(e.target.value)
    if(e.target.value >= 1 && e.target.value <= 10)
    setMintAmount(amount)
    setMintTotal(amount * mintData.mintPrice)
  }
  const handleMint = async () => {
    if(myPublicAddress != null && mintAmount >= 1 && mintAmount<=10){
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      console.log(accounts[0]);
      console.log(mintAmount,mintTotal)
      // call mint function on NFT contract
      try {
          if (await getChain(provider) === 43114) {
              const wallet = accounts[0]
              const contractAddress = "0xc74fe1e6e160f13279965c812B57FA91b30e9EE5"
              const signer = provider.getSigner()
              const connectedContract = new ethers.Contract(contractAddress, contractAbi, signer)
              let tx = await connectedContract.mint(wallet, mintAmount, {value: ethers.utils.parseEther(mintTotal+'')});
              
              if (tx.wait()) {
                console.log("Transaction is successful!!!")
                setMintMessage("You successfully minted "+mintAmount+" iLLEOo Genesis NFTs!")
              } else {
                setMintMessage("Error submitting transaction")
                console.log("Error submitting transaction")
              }
          }
          else {
            setMintMessage("Wrong network - Connect to Avalanche Mainnet first!")
              console.log("Wrong network - Connect to Avalanche Mainnet first!")
          }
      } catch (e) {
        setMintMessage("Transaction rejected! Please try again")
          console.log("Error!", e)
      }

    }
  }
  useEffect(() => {
    const getAVAXUSDPrice = async () => {
      const provider = new ethers.providers.JsonRpcProvider("https://api.avax.network/ext/bc/C/rpc");
      const aggregatorV3InterfaceABI = [
          {
              inputs: [],
              name: 'decimals',
              outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
              stateMutability: 'view',
              type: 'function'
          },
          {
              inputs: [],
              name: 'description',
              outputs: [{ internalType: 'string', name: '', type: 'string' }],
              stateMutability: 'view',
              type: 'function'
          },
          {
              inputs: [{ internalType: 'uint80', name: '_roundId', type: 'uint80' }],
              name: 'getRoundData',
              outputs: [
                  { internalType: 'uint80', name: 'roundId', type: 'uint80' },
                  { internalType: 'int256', name: 'answer', type: 'int256' },
                  { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
                  { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
                  { internalType: 'uint80', name: 'answeredInRound', type: 'uint80' }
              ],
              stateMutability: 'view',
              type: 'function'
          },
          {
              inputs: [],
              name: 'latestRoundData',
              outputs: [
                  { internalType: 'uint80', name: 'roundId', type: 'uint80' },
                  { internalType: 'int256', name: 'answer', type: 'int256' },
                  { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
                  { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
                  { internalType: 'uint80', name: 'answeredInRound', type: 'uint80' }
              ],
              stateMutability: 'view',
              type: 'function'
          },
          {
              inputs: [],
              name: 'version',
              outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
              stateMutability: 'view',
              type: 'function'
          }
      ];
      const addr = '0x0A77230d17318075983913bC2145DB16C7366156';
      const priceFeed = new ethers.Contract(addr, aggregatorV3InterfaceABI, provider);
      let roundData = await priceFeed.latestRoundData();
      let decimals = await priceFeed.decimals();
      const price = Number( mintData.mintPrice * (roundData.answer.toString() / Math.pow(10, decimals))).toFixed(2);
      setMintPriceUSD(price)
      return price;
    }
    getAVAXUSDPrice()
    if(new Date(mintData.mintDate) < new Date()){
      setMintLive(true);
    }
  })

  return (
    <>
      {/* Start */}
      <section className="bg-item-detail d-table w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="sticky-bar">
                <img
                  src={genesisPreview}
                  className="img-fluid rounded-md shadow"
                  alt=""
                />
                <div className="row g-2 mt-2">
                    {/* <div className="col-12">
                      <img
                        src={c3}
                        className="img-fluid shadow-sm rounded-md"
                        alt=""
                      />
                    </div> */}
                    {/*end col*/}

                    <div className="col-4">
                      <img
                        src={genesisPreview2}
                        className="img-fluid shadow-sm rounded-md"
                        alt=""
                      />
                    </div>
                    {/*end col*/}

                    <div className="col-4">
                      <img
                        src={genesisPreview3}
                        className="img-fluid shadow-sm rounded-md"
                        alt=""
                      />
                    </div>
                    {/*end col*/}

                    <div className="col-4">
                      <img
                        src={genesisPreview4}
                        className="img-fluid shadow-sm rounded-md"
                        alt=""
                      />
                    </div>
                    {/*end col*/}
                  </div>
                  {/*end row*/}
              </div>
            </div>

            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="ms-lg-5">
                <div className="title-heading">
                  <h4 className="h3 fw-bold mb-0">{mintData.collectionName}</h4>
                </div>

                <div className="row">
                  <div className="col-md-6 mt-4 pt-2">
                    <h6>Mint Price</h6>
                    <h4 className="mb-0">{mintData.mintPrice} {mintData.mintCurrency}</h4>
                    <small className="mb-0 text-muted">${mintPriceUSD}</small>
                  </div>

                  <div className="col-md-6 mt-4 pt-2">
                    {!mintLive && !mintEnded &&
                    <div>
                    <h6>Mint starts in</h6>
                    <Countdown
                      date={mintData.mintDate}
                      renderer={({ days, hours, minutes, seconds }) => (
                        <span>
                          {days}:{hours}:{minutes}:{seconds}
                        </span>
                      )}
                    />
                    </div>
                      }

                    {mintLive &&
                    <div>
                    <h6>Mint is LIVE!</h6>
                    {/* 0 / {mintData.totalSupply} minted */}
                    </div>
                      }
                  </div>

                  <div className="col-12 mt-4 pt-2">
                    {myPublicAddress == null ? (
                    <a
                      href="#"
                      className="btn btn-l btn-pills btn-primary me-2"
                      onClick={() => {document.getElementById('connectWallet').click()}}
                    >
                      <i className="mdi mdi-wallet fs-5 me-2"></i> Connect Wallet
                    </a>
                    ): (
                      <div>
                      {mintLive ? (
                        <a
                          href="#"
                          className="btn btn-l btn-pills btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#NftMintModal"
                        >
                          <i className="mdi mdi-cart fs-5 me-2"></i> Mint
                        </a>

                        ) : (
                          <i>Check back after mint goes live!</i>
                        )}
                    </div>
                    )}
                  </div>
                </div>

                <div className="row mt-4 pt-2">
                  <div className="col-12">
                    <ul
                      className="nav nav-tabs border-bottom"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="detail-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#detailItem"
                          type="button"
                          role="tab"
                          aria-controls="detailItem"
                          aria-selected="true"
                        >
                          Collection Details
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="bids-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#bids"
                          type="button"
                          role="tab"
                          aria-controls="bids"
                          aria-selected="false"
                        >
                          Mint Info
                        </button>
                      </li>

                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="holders-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#holders"
                          type="button"
                          role="tab"
                          aria-controls="holders"
                          aria-selected="false"
                        >
                          Holders
                        </button>
                      </li>
                    </ul>

                    <div className="tab-content mt-4 pt-2" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="detailItem"
                        role="tabpanel"
                        aria-labelledby="detail-tab"
                      >
                        <p>Genesis is the first NFT art collection of artist iLLEOo on Avalanche &#128314;, in collaboration with Greek artists and brands debuting in web3, with a clear manifest: connect the artist with his fans, while giviving back to the people that keep supporting his work.</p> <p>Our own @Lazo hand-drew the "DNA" that makes up the 1200 distinct NFTs of the collection, ready to use as a profile pic and flex you are a unique member of iLLEOo gang!</p> <p>But there's more...</p> <ul><li>1 lucky draw only for owners of any NFT with KIP item for a 2hr studio session with iLLEOo @ soundcheck studio</li><li>NFTs with a Sneaker10 or Canna-x item are eligible for discounts on the brand's eshop</li><li>Genesis NFTs count as raffle tickets for a pair of Air Jordans AJ12, courtesy of Sneaker10</li></ul>
                        <h6 className="mt-4">Artists</h6>

                        <div className="creators creator-primary d-flex align-items-center mb-3 flex-wrap">
                        {artists?.map((artist, index) => {
                          return (
                          <div className="d-flex align-items-center" key={'artist'+index}>
                            <div className="position-relative">
                              <img
                                src={artist.image}
                                className="avatar avatar-md-sm shadow-md rounded-pill"
                                alt=""
                              />
                              <span className="verified text-primary">
                                <i className="mdi mdi-check-decagram"></i>
                              </span>
                            </div>
                            <div className="ms-3">
                              <h6 className="mb-0">
                                <a
                                  href={artist.link}
                                  onClick={e => {
                                    e.preventDefault()
                                    navigate(artist.link)
                                  }}
                                  className="text-dark name"
                                >
                                  {artist.name}
                                </a>
                              </h6>
                            </div>
                            <div className="ms-3"></div>
                          </div>
                          )
                        })
                        }
                        </div>

                        <h6>Partners</h6>

                        <div className="creators creator-primary d-flex align-items-center flex-wrap">
                        {partners?.map((partner, index) => {
                          return (
                          <div className="d-flex align-items-center" key={'partner'+index}>
                            <div className="position-relative">
                              <img
                                src={partner.image}
                                className="avatar avatar-md-sm shadow-md rounded-pill"
                                alt=""
                              />
                              <span className="verified text-primary">
                                <i className="mdi mdi-check-decagram"></i>
                              </span>
                            </div>
                            <div className="ms-3">
                              <h6 className="mb-0">
                                <a
                                  href={partner.link}
                                  onClick={e => {
                                    e.preventDefault()
                                    navigate(partner.link)
                                  }}
                                  className="text-dark name"
                                >
                                  {partner.name}
                                </a>
                              </h6>
                            </div>
                            <div className="ms-3"></div>
                          </div>
                          )
                        })
                        }
                        </div>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="bids"
                        role="tabpanel"
                        aria-labelledby="bids-tab"
                      >
                        <h5>MINT</h5>
                        <p>Date: 22/07/2022 19:30 GMT+2 <br></br>
                          Price: 0.5 AVAX<br></br>
                          Total Supply: 1200<br></br>
                          Contract: <a href={"https://snowtrace.io/address/"+mintData.contractAddress} target="_blank">{mintData.contractAddress}</a>
                        </p>
                        <p><small><i>60 NFTs pre-minted and airdropped to partners & NFT Art Greece Member Badge holders </i></small></p>
                        <h6>HOW TO MINT</h6>
                        <ol>
                          <li>Connect Wallet - Switch to Avalanche C-Chain <i>(Core / Metamask recommended)</i></li>
                          <li>Press mint button</li>
                          <li>Complete the transaction that pops up on your wallet - it will cost 0.5 AVAX / NFT + gas</li>
                          <li>Your NFTs are freshly minted to your wallet 🎉 The real DNA is still hidden until...</li>
                        </ol>
                        <hr></hr>
                        <h5>REVEAL</h5>
                        <p>Date: 29/07/2022 19:30 GMT+2</p>
                        <p>The collection will now be revealed and all hidden Genesis NFTs will show their true face - you can finally see what attributes your NFT has and if you're lucky, get more of those <span className="text-muted">[Holders]</span> benefits!</p>
                        <hr></hr>
                        <h5>RAFFLE</h5>
                        <p>Date: 26/08/2022 19:30 GMT+2</p>
                        <p>All Genesis NFT owners can register in a holders-only raffle for a pair of Air Jordans AJ12, airdopped by sneaker10</p>
                        <p><i>more info TBA</i></p>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="holders"
                        role="tabpanel"
                        aria-labelledby="holders-tab"
                      >
                        Your iLLEOo - Genesis NFT is not only a way to directly support the artists you love, but also unlocks the following holders-only benefits: 
                        <ul>
                          <li>100x NFTs with Sneaker10 shoe item (Left Hand) get a -15% off code for <a href="https://www.sneaker10.gr/" target="_blank">sneaker10.gr</a></li>
                          <li>100x NFTs with any Canna-x item (Left Hand or Body) get a -15% off code for <a href="https://hempoilshop.gr/" target="_blank">hempoilshop.gr</a></li>
                          <li>All Genesis NFT owners can register in a holders-only raffle for a pair of Air Jordans AJ12, airdopped by sneaker10 - <i> dates & more info TBA</i></li>
                          <li>1 lucky draw for owners of any NFT with KIP item (chain or cap) for a 2hr studio session with iLLEOo at <a href="https://www.instagram.com/soundcheckstudio.gr/" target="_blank">soundcheckstudio.gr</a> - <i> dates & more info TBA</i></li>
                        </ul>
                        <a href={"https://joepegs.com/collections/"+mintData.contractAddress}>Trade on joepegs</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End */}
      <div
        className="modal fade"
        id="NftMintModal"
        aria-hidden="true"
        aria-labelledby="mintModalTitle"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="modal-header">
              <h5 className="modal-title" id="mintModalTitle">
                Mint iLLEOo Genesis
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label fw-bold">
                        Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        name="amount"
                        id="mintAmount"
                        type="number"
                        className="form-control"
                        placeholder="1"
                        value={mintAmount}
                        min="1"
                        max="10"
                        onChange={handleAmountChanged}
                      />
                      <small className="text-muted">
                        <span className="text-dark">Note:</span> Max 10 / transaction
                      </small>
                    </div>
                  </div>
                  {/*end col*/}
                </div>
              </form>

              <div className="pt-3 border-top">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> Total:</p>
                  <p className="text-primary mb-0"> {mintTotal} {mintData.mintCurrency} </p>
                </div>
              </div>
              <div className="mt-3">
                <div className="row">
                  <div className="col-12">
                    <p className="text-muted"><i>{mintMessage}</i></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-pills btn-primary"
                onClick={handleMint}
              >
                <i className="mdi mdi-cart fs-5 me-2"></i> Mint
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* footer */}
      <Footer />
    </>
  )
}

export default MintPage
