import React, { useEffect } from 'react'
import Footer from '../../components/Footer'
import { coreWallet, coreWalletGuide, coreCreateWallet, coreInstall, iconLogo } from '../../components/imageImport'

const BlogCoreWallet = () => {
  useEffect(() => {
    window.Tobii()
  }, [])

  return (
    <>
      {/* Start Blog Detail */}
      <section className="section mt-5">
        <div className="container">
          <div className="row mt-5 mt-lg-0 justify-content-center">
            <div className="col-lg-8">
              <div className="title-heading">
                <h4 className="heading sub-heading fw-bold mb-3">
                  Here's a quick guide on how to create your own crypto wallet with Core extension!
                </h4>
                <p className="text-muted fs-5 mb-0">
                  Your wallet holds access to your funds and digital assets on the blockchain, thus it is very important that you use secure software and be mindful to take measures to stay safe!
                </p>

                <div className="d-flex align-items-center mt-4">
                  <img src={iconLogo} style={{width: 48}} className="rounded-pill shadow-md" alt="" />

                  <div className="content ms-2">
                    <a
                      href=""
                      onClick={e => e.preventDefault()}
                      className="h6 text-dark d-block mb-0"
                    >
                      NFT Art Greece
                    </a>
                    <small className="text-muted mb-0">
                      20th July 2022, 5 min read
                    </small>
                  </div>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-12 mt-5">
              <div className="position-relative">
                <img
                  src={coreWallet}
                  className="img-fluid rounded-md shadow-md"
                  alt=""
                />
                <div className="play-icon">
                  <a
                    href="#!"
                    data-type="youtube"
                    data-id="eczkjrGG_As"
                    className="play-btn lightbox"
                  >
                    <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg icons"></i>
                  </a>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-8 mt-5">
              <p className="text-muted">
                Core is an all-in-one Web3 wallet that allows users to interact with AVAX native Dapps, DeFi GameFI, NFTs, Bridges, Subnets, and more. Other key features underpinning the new wallet include:
              </p>

              <p className="text-muted">
                <ul>
                  <li><strong>Fund with cash</strong> – buy AVAX directly on your Core wallet with credit card in just a few clicks (powered by MoonPay)</li>
                  <li><strong>Bridge</strong> – bridge your native Bitcoin and Ethereum tokens (ERC-20) using the same trusted technology powering the Avalanche Bridge</li>
                  <li><strong>Swap</strong> – swap hundreds of Avalanche tokens directly from Core (powered by ParaSwap)</li>
                  <li><strong>Ledger-enabled</strong> – compatible with industry-leading, secure hardware wallet </li>
                  <li><strong>Collectibles Gallery</strong> – manage and display all of your favorite NFTs in one consolidated portal</li>
                  <li><strong>Portfolio</strong> – view all assets in a unified display without having to manually add token addresses or switch networks</li>
                  <li><strong>Address Book</strong> – create contacts to save frequently used, trusted addresses</li>
                  <li><strong>Account Switcher</strong> – create and manage multiple addresses using the same recovery(seed) phrase</li>
                </ul>
              </p>

              <div className="px-4 py-5 rounded-md bg-soft-primary text-center mt-4">
                <h4 className="mb-0">
                  "Core extension is your gateway to the Avalanche ecosystem and beyond."
                </h4>
              </div>

              <h3 className="mt-5">DOWNLOAD AND SETUP</h3>
              <ul>
                <li>Search in the official Google Chrome web store for <a href="https://chrome.google.com/webstore/detail/core/agoakfejjabomempkjlepdflaleeobhb" target="_blank">Core Wallet</a></li>
                <li>Install Avalanche Core on your Chrome browser.</li>
                <li>Open the browser extension and follow the steps provided to you on screen.</li>
              </ul>

              <div className="mt-4">
                <img
                  src={coreInstall}
                  className="img-fluid rounded-md shadow"
                  alt=""
                />
              </div>
              <div className="mt-4">
                <img
                  src={coreCreateWallet}
                  className="img-fluid rounded-md shadow"
                  alt=""
                />
              </div>
              <p className="text-muted">
                Congratulations! You now have your own, self-custodial wallet account 🎉 <br></br>
                Send or buy AVAX tokens to your account to start interacting with Web3!
              </p>
              <h3 className="mt-5">CORE WALLET FEATURES</h3>

              <div className="row mt-5  mb-4 mt-lg-0 justify-content-center">
                <div className="col-lg-6">
                  <img
                    src={coreWalletGuide}
                    className="img-fluid rounded-md shadow"
                    alt=""
                  />
                </div>
                <div className="col-lg-6">
                  <ol>
                    <li><strong>Menu</strong><br></br> access app preferences, Address Book etc</li>
                    <li><strong>Switch Account</strong><br></br> quickly switch/view balance overview for all your accounts</li>
                    <li><strong>Switch Network</strong><br></br> you can switch from Primary Avalanche network (c-chain) to any subnet</li>
                    <li><strong>Funding</strong><br></br> send / receive / bridge from other chain / swap funds</li>
                    <li><strong>Buy with fiat</strong><br></br> buy crypto using your credit card, powered by MoonPay</li>
                  </ol>
                </div>
              </div>

              <h3>STAYING SAFE</h3>
              <p className="text-muted">
                <ul>
                  <li>Use a <strong>strong</strong> wallet password.</li>
                  <li>NEVER share your seed phrase with anyone! It will be never requested by any official support from Ava Labs or any other organization.</li>
                  <li>Do not click on suspicious links from unkown sources from the device with your wallet!</li>
                  <li>Do not sign uknown contracts requesting permission to access your funds - most probably these are malicious actors trying to drain your wallet!</li>
                </ul>
              </p>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Blog Detail */}

      {/* footer */}
      <Footer />
    </>
  )
}

export default BlogCoreWallet
